<template>
  <q-card class="full-width" :style="{ maxWidth: '400px' }">
    <q-card-section>
      <h3 class="text-h6 q-mb-sm q-mt-none">Update</h3>

      <q-form @submit.prevent="onSubmitForm">
        <q-input
          v-model.trim="form.name"
          :rules="[fieldRules.required]"
          autofocus
          outlined
          label="Account Name"
          class="q-mb-md"
        />

        <div class="row q-mt-md">
          <section class="col-6">
            <div class="q-pr-sm">
              <q-btn
                :disable="form.loading"
                no-caps
                unelevated
                outline
                type="button"
                color="red"
                label="Cancel"
                class="full-width"
                @click="onCancel"
              />
            </div>
          </section>

          <section class="col-6">
            <div class="q-pl-sm">
              <q-btn
                :loading="form.loading"
                :disable="disabledForm"
                no-caps
                unelevated
                type="submit"
                color="primary"
                label="Update"
                class="full-width"
              />
            </div>
          </section>
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import { accountController } from '@/shared/api';
import { notify } from '@/shared/utils';

export default {
  props: {
    accountName: {
      type: String,
      required: true,
    },
    accountId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        loading: false,
        name: this.accountName,
      },
    };
  },
  computed: {
    fieldRules() {
      return {
        required: (value) =>
          !!value?.toString().trim().length || 'Please type value',
      };
    },
    disabledForm() {
      return this.form.name === this.accountName;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmitForm() {
      if (this.form.loading) return;

      this.form.loading = true;

      try {
        await accountController.updateRefillAccount({
          id: this.accountId,
          name: this.form.name,
        });

        this.form.loading = false;
        this.$emit('update:account-name', this.form.name);
        this.$emit('updated');
        notify.success('Account name updated');
      } catch {
        this.form.loading = false;
      }
    },
  },
};
</script>
